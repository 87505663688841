import React, {Component} from 'react';
import {Button, Col, Form} from "react-bootstrap";
import './contactFormStyles.css'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

class ContactForm extends Component {
    render() {
	  const {onChange, onSubmit, setPhoneNumber, data} = this.props;
	  const {validName, validPhone, validEmail, validSubject, validMessage,
	  		name, phone, email, subject, message} = data;
	  return (
		<Form className={'contact-form'}>
		    <Form.Row>
			  <Form.Group as={Col} lg={6} md={6} xs={12} sm={12} controlId="formGridName">
				<Form.Label>Name</Form.Label>
				<Form.Control
				    type="text" placeholder="Name" onChange={(e) => onChange('name', e)}
				    isValid={validName} isInvalid={validName !== undefined && !validName} value={name}/>
			  </Form.Group>

			  <Form.Group as={Col} lg={6} md={6} xs={12} sm={12} controlId="formGridPhone">
				<Form.Label>Phone</Form.Label>		
				<PhoneInput
					country={'us'}
					value={phone}
					onChange={(e) => setPhoneNumber(e)} 
					isValid={validPhone} 
					isInvalid={validPhone !== undefined && !validPhone}
					inputProps={{
						name: 'phone',
						required: true
					  }}
					/>
			  </Form.Group>
		    </Form.Row>

		    <Form.Row>
			  <Form.Group as={Col} lg={6} md={6} xs={12} sm={12} controlId="formGridEmail">
				<Form.Label>Email</Form.Label>
				<Form.Control
				    type="email" placeholder="Email" onChange={(e) => onChange('email', e)}
				    isValid={validEmail} isInvalid={validEmail !== undefined && !validEmail} value={email}/>
			  </Form.Group>

			  <Form.Group as={Col} lg={6} md={6} xs={12} sm={12} controlId="formGridSubject">
				<Form.Label>Subject</Form.Label>
				<Form.Control
				    type="text" placeholder="Subject" onChange={(e) => onChange('subject', e)}
				    isValid={validSubject} isInvalid={validSubject !== undefined && !validSubject} value={subject}/>
			  </Form.Group>
		    </Form.Row>

		    <Form.Group controlId="formGridMessage">
			  <Form.Label>Message</Form.Label>
			  <Form.Control
				as="textarea" rows="3"
				placeholder="Message"
				onChange={(e) => onChange('message', e)} isValid={validMessage}
				isInvalid={validMessage !== undefined && !validMessage} value={message}/>
		    </Form.Group>

		    <Button style={{float: 'right'}} block variant="primary" onClick={() => onSubmit()}>
			  Send
		    </Button>
		</Form>
	  )
    }
}

export default ContactForm;