import React, { Component } from "react";
import {Col, Form} from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

class ContactInformation extends Component{
    render(){
        const {onChange, setPhoneNumber, data} = this.props;
        const { firstname,lastname,businessname,email,phone, validfirstname,validlastname, validphone, validemail } = data;
        console.log("DATA", firstname);

        return(
            <Form className={'quotation-form'}>
                <h4 style={{textAlign: 'left', marginTop: '15px'}}>Contact Information</h4>
                <Form.Row>
                    <Form.Group as={Col} lg={6} md={6} xs={12} sm={12} controlId="formGridName">
                        <Form.Label>First Name*</Form.Label>
                        <Form.Control
                            type="text" placeholder="First Name" onChange={(e) => onChange('firstname', e)}
                            isValid={validfirstname} isInvalid={validfirstname !== undefined && !validfirstname} value={firstname}/>
                    </Form.Group>

                    <Form.Group as={Col} lg={6} md={6} xs={12} sm={12} controlId="formGridName">
                        <Form.Label>Last Name*</Form.Label>
                        <Form.Control
                            type="text" placeholder="Last Name" onChange={(e) => onChange('lastname', e)}
                            isValid={validlastname} isInvalid={validlastname !== undefined && !validlastname} value={lastname}/>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} lg={6} md={6} xs={12} sm={12} controlId="formGridName">
                        <Form.Label>Bussiness Name</Form.Label>
                        <Form.Control
                            type="text" placeholder="Bussiness Name" onChange={(e) => onChange('businessname', e)}
                            value={businessname}/>
                    </Form.Group>
                    <Form.Group as={Col} lg={3} md={6} xs={12} sm={12} controlId="formGridEmail">
                        <Form.Label>Email*</Form.Label>
                        <Form.Control
                            type="email" placeholder="Email" onChange={(e) => onChange('email', e)}
                            isValid={validemail} isInvalid={validemail !== undefined && !validemail} value={email}/>
                    </Form.Group>
                    <Form.Group as={Col} lg={3} md={6} xs={12} sm={12} controlId="formGridPhone">
                        <Form.Label>Phone Number*</Form.Label>
                        <PhoneInput
                            country={'us'}
                            value={phone}
                            onChange={(e) => setPhoneNumber(e)} 
                            isValid={validphone} 
                            isInvalid={validphone !== undefined && !validphone}
                            inputProps={{
                                name: 'phone',
                                required: true
                            }}
                            />
                        {/* <Form.Control
                            type="text" placeholder="Ex: +94776782389"
                            onChange={(e) => onChange('phone', e)} isValid={validphone}
                            isInvalid={validphone !== undefined && !validphone} value={phone}/> */}
                    </Form.Group>
                </Form.Row>
            </Form>
        )
    }
    
}

export default ContactInformation;