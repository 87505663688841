import React, {Component} from 'react';
import {Col, Image, Row} from "react-bootstrap";

class FeatureItem extends Component{
    render() {
        const {src, heading, description} = this.props;
        return(
            <Row className={'feature-item'}>
		    <Col className={'feature-item-contents'}>
			  <Image fluid src={src}/>
			  <div>
				<h1>{heading}</h1>
				<p>{description}</p>
			  </div>
		    </Col>
		</Row>
	  )
    }
}
export default FeatureItem;