import React, {Component} from 'react';
import {Col, Container, Image, Modal, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FaBars} from "react-icons/fa";
import './headerStyles.css'

const pages = [
    {
	  name: 'Home',
	  route: '/'
    }, {
	  name: 'Our Process',
	  route: '/what-we-do'
	},{
		name: 'Our Work',
		route: '/our-work'
	},{
		name: 'Services',
		route: '/services'
	},{
		name: 'Contact Us',
		route: '/contact-us'
    },{
		name: 'Get a Quote within 24Hrs',
		route: '/quotation/Web Development - WordPress'
	}
]

class Header extends Component {
    state = {
	  mobileHeaderActive: false
    }

    renderPageLinks = pages => {
	  const options = []
	  pages.forEach(page => {
		options.push(
			(page.route == '/quotation/Web Development - WordPress') ?
		    <Link style={{textDecoration: 'none', fontWeight: 800, color: '#f5f5dc', border: "solid 2px #007bff", padding: "5px 15px 5px 15px", borderRadius: "4px", backgroundColor: "#007bff", fontSize: '15px'}} onClick={() => this.closeMobileHeader()} key={page.route} to={page.route}>
			  <span>{page.name}</span>
		    </Link> : 
			 <Link className={'navigation_link'} style={{padding: "5px", borderRadius: "2px"}} onClick={() => this.closeMobileHeader()} key={page.route} to={page.route}>
				<span>{page.name}</span>
			</Link>
		)
	  });
	  return options;
    }

    onActivateMobileHeader = () => {
	  this.setState({mobileHeaderActive: !this.state.mobileHeaderActive})
    }
    closeMobileHeader = () => {
	  this.setState({mobileHeaderActive: false})
    }

    render() {
	  const {mobileHeaderActive} = this.state;
	  window.onscroll = function () {
		let header = document.getElementById('header');
		let sticky = header.offsetTop;
		if (window.pageYOffset > sticky) {
		    header.classList.add("sticky");
		} else {
		    header.classList.remove("sticky");
		}
	  }
	  return (
		<Container id={'header'} style={{maxWidth: "100%"}}>
		    <Container style={{padding: 0, maxWidth: 1300}}>
				<Row className={'mobileHeader'} style={styles.navigationRowMobile}>
				    <Col style={styles.headerLogoCol} lg={4} md={4} sm={6} xs={6}>
					  <Link to={'/'} className={'navigation_link'}>
						<Image src={"images/logo-main.png"} lg={"auto"} md={"auto"} style={{width: 100}}/>
					  </Link>
				    </Col>
				    <Col sm={6} xs={6} style={{textAlign: 'right'}}><FaBars
					  onClick={() => this.onActivateMobileHeader()}/></Col>
				</Row>
				<Row className={'desktopHeader'} style={styles.navigationRowDesktop}>
				    <Col style={styles.headerLogoCol} lg={3} md={3} sm={6} xs={6}>
					  <Link to={'/'} className={'navigation_link'}>
						<Image src={"images/logo-main.png"} lg={"auto"} md={"auto"} style={{width: 135}}/>
					  </Link>
				    </Col>
				    <Col style={styles.navigationLinkCol} lg={9} md={9} sm={6} xs={6}>
					  {this.renderPageLinks(pages)}
				    </Col>
				</Row>

			  <Modal show={mobileHeaderActive} onHide={() => this.onActivateMobileHeader()}
				   style={{width: '100% !important', margin: '0px !important'}}>
				<Modal.Body>
				    <Col style={styles.mobileNavigationLinkCol} lg={4} md={8} sm={false} xs={0}>
					  {this.renderPageLinks(pages)}
				    </Col>
				    <h3 style={{position: 'absolute', right: 10, top: 5}}
					  onClick={() => this.onActivateMobileHeader()}>x</h3>
				</Modal.Body>
			  </Modal>
		    </Container>
		</Container>
	  )
    }
}

export default Header;

const styles = {
    navigationLink: {
	  color: '#ffffff'
    }, navigationRowDesktop: {
	  display: 'flex',
	  alignItems: 'center',
	  marginTop: 10,
	  marginBottom: 10
    }, navigationRowMobile: {
	  display: 'flex',
	  marginTop: 10,
	  marginBottom: 10
    },
    headerLogoCol: {
	  display: 'flex'
    },
    navigationLinkCol: {
		display: 'flex',
		justifyContent: 'end',
		columnGap: 15
    },
    mobileNavigationLinkCol: {
	  display: 'flex',
	  flexDirection: 'column'
    }
}
