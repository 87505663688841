import React, {Component} from "react";
import { Form, Col } from "react-bootstrap";

class FigmaDesignInformation extends Component{
    render() {
        const {onChange, data} = this.props;
        const { screencount, validscreencount, provideimages, validprovideimages} = data;

        return(
            <Form>
                <h4 style={{textAlign: 'left', 'marginTop': '15px'}}>Design Information</h4>
                {/* Do you have a business logo? */}
                <Form.Row style={{marginTop: '10px'}}>
                    <Form.Group as={Col} lg={6} md={6} xs={12} sm={12} controlId="formGridName">
                        <Form.Label>How many screens or components to be converted? *</Form.Label><br/>
                        <Form.Control
                            as="textarea" rows="2"
                            placeholder=""
                            onChange={(e) => onChange('screencount', e)} isValid={validscreencount}
                            isInvalid={validscreencount !== undefined && !validscreencount} value={screencount}/>
                    </Form.Group>
                    <Form.Group as={Col} lg={6} md={6} xs={12} sm={12} controlId="formGridName">
                        <Form.Label>Would you be providing required images? *</Form.Label><br/>
                        <Form.Control
                            as="textarea" rows="2"
                            placeholder=""
                            onChange={(e) => onChange('provideimages', e)} isValid={validprovideimages}
                            isInvalid={validprovideimages !== undefined && !validprovideimages} value={provideimages}/>
                    </Form.Group>
                </Form.Row>
            </Form>
        )
    }
}

export default FigmaDesignInformation;