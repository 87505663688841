import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import TechnologyItem from "./TechnologyItem";
import './technologyGridStyles.css';

class TechnologyGrid extends Component{
    renderTechnologyItems = () => {
	  const {technologies} = this.props;
	  const itemList = [];
	  technologies && technologies.length > 0 && technologies.forEach(technology => {
		itemList.push(<Col lg={3} md={3} sm={6} xs={6} key={technology.name} className={'text-left technology-col'}>
		    <TechnologyItem
			  src={technology.src}
			  heading={technology.name}
		    />
		</Col>)
	  })
	  return itemList;
    }
    render() {
        return(
		<Row>
		    {this.renderTechnologyItems()}
		</Row>
	  )
    }
}
export default TechnologyGrid;