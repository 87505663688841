import React, {Component} from 'react';
import {Col, Image, Row} from "react-bootstrap";
import {
    FaMapMarker,
    FaPhoneSquare,
    FaEnvelope,
    FaLinkedinIn,
    FaInstagram,
    FaFacebook,
    FaTwitter,
    FaGithub
} from 'react-icons/fa';

class FooterContacts extends Component {
    render() {
	  return (
		<Row className={'footer-contact'}>
		    <Col lg={2} md={2} sm={12} xs={12} style={{textAlign: 'left'}}>
			  <Image src={'images/logo-icon-white.png'} style={{height: 60, marginBottom: 20}}/>
		    </Col>
		    <Col lg={3} md={3} sm={12} xs={12} style={{textAlign: 'left', display: '-webkit-box'}}>
				<div>
					<h4>Sri Lanka</h4>
					<p style={{paddingTop: 1}}> 
						<FaMapMarker style={{marginRight: 10}}/>265/2,<br/>
						<div style={{marginLeft: '24px'}}>
							Sri Saddharma Mawatha,<br/>
							Colombo 10,<br/>
							Sri Lanka.
						</div>
						<a href={'tel:+94776994049'} style={{textDecoration: 'none', color: '#FFFFFF'}}><FaPhoneSquare style={{marginRight: 10}}/> +94 776 99 40 49</a><br/>
					</p>
					<br/>
				</div>
		    </Col>
		    <Col lg={4} md={4} sm={12} xs={12} style={{textAlign: 'left'}}>
				<div>
					<h4>Sweden</h4>
					<p style={{paddingTop: 1}}> 
						<FaMapMarker style={{marginRight: 10}}/>Nedersta Alle 37<br/>
						<div style={{marginLeft: '24px'}}>
							13739,<br/>
							Västerhanninge,<br/>
							Sweden.
						</div>
						{/* <a href={'tel:+94776994049'} style={{textDecoration: 'none', color: '#FFFFFF'}}><FaPhoneSquare style={{marginRight: 10}}/> +46 76 246 46 76</a><br/> */}
					</p>
					<br/>
				</div>
		    </Col>
		    <Col lg={3} md={3} sm={12} xs={12} style={{textAlign: 'left', paddingTop: 10, marginBottom: 20, display: 'grid', gridAutoFlow: 'column'}}>
				<div>
					<a style={styles.socialIconStyle} href={"https://www.facebook.com/incylabs"} target={'_blank'}><FaFacebook/></a>
					<a style={styles.socialIconStyle} href={'https://www.linkedin.com/company/incy-private-ltd/'} target={'_blank'}><FaLinkedinIn/></a>
					<a style={styles.socialIconStyle} href={'https://www.instagram.com/incylabs'} target={'_blank'}><FaInstagram/></a>
					<a style={styles.socialIconStyle} href={'https://twitter.com/IncyLabs'} target={'_blank'}><FaTwitter/></a>
					<a style={styles.socialIconStyle} href={'https://github.com/Incylabs'} target={'_blank'}><FaGithub/></a>
					<br/><br/>
					<a href={'mailto:hello@incylabs.com'} style={{textDecoration: 'none', color: '#FFFFFF'}}><FaEnvelope style={{marginRight: 10}}/> hello@incylabs.com</a>
				</div>
		    </Col>
		</Row>
	  )
    }
}
const styles= {
    socialIconStyle: {
        textDecoration: 'none',
	  borderRadius: '100%',
	  marginRight: 10,
	  color: '#FFFFFF',
	  fontSize: 25
    }
}


export default FooterContacts;