import React, {Component} from 'react';
import {Container} from "react-bootstrap";
import FeatureGrid from "../../components/FeatureGrid";
import services from "../../Data/services";
import PageBanner from "../../components/PageBanner";
import {Title, TitleUnderline} from "../../components/CommonHelpers";

class Home extends Component {
    constructor(props) {
	  super(props)
	  this.top = React.createRef()
	  const defaultFormType = 'Web Development - WordPress';
    }

    componentDidMount() {
	  this.top.current.scrollTo(0, 0);
    }

    render() {
	  return (
		<section>
		    <Container ref={this.top}>
			  <PageBanner
				boldHeading={'Boutique Application Development'}
				heading={null}
				description={'Unparrelled Design, Development & Team Augmentation'}
				buttonLink={'/quotation/Web Development - WordPress'}
				buttonText={'Get a quote'}
				imageUrl={'images/dev-banner2.png'}
			  />
		    </Container>

		    <section className={'section-outer'} style={{marginTop: -85}}>
			  <Container style={{
				display: 'flex',
				alignItems: 'center',
				fontSize: 20,
			  }}>
				<div>
				    <p className={'common-para mobile-center'} style={{textAlign: 'justify'}}>Welcome to Incy Labs, a software engineering company based in Sri Lanka. 
					Since 2015, we have been delivering unique solutions to businesses around the world. Our skilled professionals, including consultants based in Sweden, 
					are dedicated to creating custom software engineering solutions that meet the specific needs of our clients. Contact us to learn how we can help your business succeed with our expertise. 
					We respond to inquiries within 24 hours..</p>
				</div>
			  </Container>
		    </section>

		    <section className={'section-outer'} style={{borderBottom: 0}}>
			  <Container>
				<Title title={'Services'}/>
				<TitleUnderline/>
				<FeatureGrid features={services}/>
			  </Container>
		    </section>

		</section>
	  )
    }
}

export default Home;