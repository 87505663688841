import React, {Component} from 'react';
import {Image} from "react-bootstrap";
import './AvatarStyles.css'
import {FaFacebook, FaInstagram, FaLinkedin, FaTwitter} from "react-icons/fa";

class Avatar extends Component{
    render() {
        const {profile} = this.props;
        const {name, src, position, facebook, linkedIn, twitter, instagram } = profile;
        return(
            <div className={'avatar'}>
		    <Image fluid src={src}/>
		    <h3>{name}</h3>
		    <span>{position}</span>
		    <div>
			  {facebook && <a href={facebook}  target="_blank" rel="noopener noreferrer"><FaFacebook/></a>}
			  {twitter && <a href={twitter}  target="_blank" rel="noopener noreferrer"><FaTwitter/></a>}
			  {linkedIn && <a href={linkedIn}  target="_blank" rel="noopener noreferrer"><FaLinkedin/></a>}
			  {instagram && <a href={instagram}  target="_blank" rel="noopener noreferrer"><FaInstagram/></a>}
		    </div>
		</div>
	  )
    }
}
export default Avatar;