import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import FeatureItem from "./FeatureItem";

class FeatureGrid extends Component {
    renderFeatureItems = () => {
        const {features} = this.props;
	  const itemList = [];
	  features.length > 0 && features.forEach(feature => {
		itemList.push(<Col lg={4} md={4} sm={12} xs={12} key={feature.imageUrl}>
		    <FeatureItem
			  src={feature.imageUrl}
			  heading={feature.heading}
			  description={feature.description}
		    />
		</Col>)
	  })
	  return itemList;
    }

    render() {
	  return (
		<Row>
		    {this.renderFeatureItems()}
		</Row>
	  )
    }
}

export default FeatureGrid;