import React, {Component} from 'react';
import {Badge, Button, Card, Col, Image, Row} from "react-bootstrap";
import {FaCode, FaDatabase} from 'react-icons/fa'

class ProductItem extends Component{
    renderTags = tagList => {
        const tags = [];
        tagList.forEach(tag => {
		    tags.push(<Badge key={tag}>{tag}</Badge>)
	  });
        return tags;
    }

    render() {
	  const {src,headerImage, heading, description, technologies, backEnd, link} = this.props;
        return(
			<Card lg={12} style={{ alignItems: 'center'}}>
				<Card.Img variant="top" src={headerImage} style={{  objectFit:'cover', height: '50%'}}/>
				
				<Card.Body style={{width: '100%'}}>
					<Card.Title><Image fluid src={src} style={{ height: '40px', padding: '5px'}}/>{heading}</Card.Title>
					<Card.Text style={{width: '100%', marginBottom:'20px'}}>
						{description}
					</Card.Text>
					
					<div style={{padding:'0px', marginBottom: '10px'}}>
						<h5>Technology : <h5>{technologies && technologies.length > 0 && this.renderTags(technologies)}</h5></h5>						
					</div>
					<div style={{height: '20px'}}>
						{link && <a href={link} target='_blank'>Visit site</a>}
					</div>
				</Card.Body>
			</Card>
	  )
    }
}
export default ProductItem;