const technologies= [
    {
	  src: 'images/auth0-logo.png',
	  name: 'Auth0'
    },{
	  src: 'images/android-logo.png',
	  name: 'Android'
    },{
	  src: 'images/cloudinary-logo.png',
	  name: 'Cloudinary'
    }, {
	  src: 'images/firebase-logo.png',
	  name: 'Firebase'
    }, {
	  src: 'images/graphql-logo.png',
	  name: 'GraphQL'
    }, {
	  src: 'images/mongodb-logo.png',
	  name: 'Mongo DB'
    }, {
	  src: 'images/mailchimp-logo.png',
	  name: 'Mail Chimp'
    }, {
	  src: 'images/java-play-logo.png',
	  name: 'Java Play'
    }, {
	  src: 'images/redux-logo.png',
	  name: 'Redux'
    }, {
	  src: 'images/rabbitmq-logo.png',
	  name: 'RabbitMQ'
    }, {
	  src: 'images/react-native-logo.png',
	  name: 'React Native'
    },{
	  src: 'images/react-logo.png',
        name: 'ReactJS'
    },  {
	  src: 'images/realm-logo.svg',
        name: 'Realm'
    }, {
	  src: 'images/stripe-logo.png',
	  name: 'Stripe'
    }, {
	  src: 'images/zendesk-logo.png',
	  name: 'Zendesk'
    }
]
export default technologies;