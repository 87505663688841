import React, {Component} from 'react';
import {Container} from "react-bootstrap";
import PageBanner from "../../components/PageBanner";
import {Title, TitleUnderline} from "../../components/CommonHelpers"
import ProductGrid from "../../components/ProductsGrid";
import products from "../../Data/products";
import TechnologyGrid from "../../components/TechnologyGrid";
import technologies from "../../Data/technologies";
import FeatureGrid from "../../components/FeatureGrid";
import services from "../../Data/services";
import agileProcess from "../../Data/agileProcess";

class WhatWeDo extends Component {
    constructor(props) {
	  super(props)
	  this.top = React.createRef()
    }

    componentDidMount() {
	  this.top.current.scrollTo(0, 0);
    }

    render() {
	  return (
		<section>
		    <Container ref={this.top}>
			  <PageBanner
				boldHeading={'Agile Development & Team Augmentation'}
				desComponent={<ul className={'agile-banner-content'}>
				    <li style={{marginTop: 10}}>Capture User Stories</li>
				    <li>Prioritize requirements and define sprints</li>
				    <li>Begin Sprints</li>
				    <li>Conduct Scrum Meetings</li>
				    <li>Submit UI Deliverables</li>
				    <li>Submit Functional Deliverables</li>
				    <li>Handover finished product and begin support phase</li>
				</ul>}
				imageUrl={'images/agile-development.png'}
			  />
		    </Container>

		    <section className={'section-outer'} style={{borderBottom: 0}}>
			  <Container>
				<Title title={'Agile Development Process'}/>
				<TitleUnderline/>
				<FeatureGrid features={agileProcess}/>
			  </Container>
		    </section>
		</section>
	  )
    }
}

export default WhatWeDo;