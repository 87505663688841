const agileProcess = [
    {
        heading: "Capture User Stories",
	  description: "Listening to our clients story and learn what they need. \n" +
		"Figure out their requirements in the form of user stories.",
	  imageUrl: "images/client-requirements.png"
    },
    {
        heading: "Prioritize requirements and define sprints",
	  description: "If client has many ideas and features, we capture all of it and prioritize them. ",
	  imageUrl: "images/sprints.png"
    },
    {
        heading: "Begin Sprints",
	  description: "We then, begin developing the project using Sprints.",
	  imageUrl: "images/start-sprint.png"
    },
    {
        heading: "Conduct Scrum Meetings",
	  description: "Host stadup meetings as and when required.\n" +
		"   We get customers's sign-off on all deliverables after each stage.\n",
	  imageUrl: "images/scrum-meeting.png"
    },
    {
        heading: "Submit UI Deliverables",
	  description: "UI design and development sprint usually goes first, and we lock down customer's requirement with UI flow.",
	  imageUrl: "images/ui-deliverables.png"
    },
    {
        heading: "Submit Functional Deliverables",
	  description: "Development sprints, add functionality to UI elements.",
	  imageUrl: "images/development-deliverables.png"
    },
    {
	  heading: "Handover finished product and begin support phase",
	  description: "Once all sprints are over and the deliverables are tested, the project will be handed over to the customer.",
	  imageUrl: "images/delivary.png"
    }
]

export default agileProcess;