import React, {Component} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FaAngleRight} from 'react-icons/fa'

class QuoteBanner extends Component {
    render() {
	  return (
		<Row style={styles.quoteContainer} className={'footer-quote'}>
		    <Col lg={6} md={6} sm={12} xs={12} style={{textAlign: 'left'}}>
			  <h2 style={styles.quoteHeader}>Need a quote?</h2>
			  <h6>We are here to help and answer any question you might have about our
				services and products. We look forward to hearing from you.</h6>
		    </Col>
		    <Col lg={6} md={6} sm={12} xs={12} style={{textAlign: 'left'}}>
			  <Row>
				<Col lg={6} md={12} sm={12} xs={12} style={{textAlign: 'right', paddingTop: 5}}>
				    <Link to={'/quotation/Web Development - WordPress'} style={{textDecoration: 'none'}}><Button size={'lg'} block>Get a
					  free
					  quote <FaAngleRight/></Button></Link>
				</Col>
				<Col lg={6} md={12} sm={12} xs={12} style={{textAlign: 'right', paddingTop: 5}}>
				    <Link to={'/contact-us'} style={{textDecoration: 'none'}}><Button size={'lg'} block>Contact
					  Us <FaAngleRight/></Button></Link>
				</Col>
			  </Row>
		    </Col>
		</Row>
	  )
    }
}

export default QuoteBanner;

const styles = {
    quoteContainer: {
	  minHeight: 150,
	  paddingTop: 20,
	  display: 'flex',
	  alignItems: 'center',
	  borderBottom: '1px solid #161b23'
    },
    quoteHeader: {
	  fontSize: 36,
	  letterSpacing: -.04,
	  fontWeight: 700
    },
    quotePara: {
	  fontSize: 20,
	  letterSpacing: -.01,
	  margin: '15px 0 25px',
	  width: '90%'
    }
}