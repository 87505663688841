import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from './containers/Home'
import About from './containers/About'
import Header from "./components/Header";
import WhatWeDo from "./containers/WhatWeDo";
import ContactUs from "./containers/ContactUs";
import Footer from "./components/Footer";
import Quotation from './containers/Quotation';
import OurWork from './containers/OurWork';
import Services from './containers/Services';

class Root extends Component {

    render = () => {
	  return (
		<Router>
		    <Header/>
		    <Route path='/' exact component={Home} />
		    <Route path='/about-us' exact component={About} />
		    <Route path='/what-we-do' exact component={WhatWeDo} />
		    <Route path='/contact-us' exact component={ContactUs} />
			<Route path='/quotation/:formType' component={Quotation} />
			<Route path='/our-work' exact component={OurWork} />
			<Route path='/services' exact component={Services} />
		    <Footer/>
		</Router >
	  );
    }
}
export default Root;