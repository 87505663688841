import React, {Component} from 'react';
import {Container} from "react-bootstrap";
import PageBanner from "../../components/PageBanner";
import {Title, TitleUnderline} from "../../components/CommonHelpers"
import ProductGrid from "../../components/ProductsGrid";
import products from "../../Data/products";
import TechnologyGrid from "../../components/TechnologyGrid";
import technologies from "../../Data/technologies";
import FeatureGrid from "../../components/FeatureGrid";
import services from "../../Data/services";
import agileProcess from "../../Data/agileProcess";

class OurWork extends Component {
    constructor(props) {
	  super(props)
	  this.top = React.createRef()
    }

    componentDidMount() {
	  this.top.current.scrollTo(0, 0);
    }

    render() {
	  return (
		<section>
		    <section className={'section-outer'} style={{borderBottom: 0, marginTop: '50px'}}>
			  <Container ref={this.top}>
				{/* <Title title={''}/> */}
				{/* <TitleUnderline/> */}
				<ProductGrid products={products}/>
			  </Container>
		    </section>

            <section className={'section-outer'}>
			  <Container>
				<Title title={'Technologies'}/>
				<TitleUnderline/>
				<TechnologyGrid technologies={technologies}/>
			  </Container>
		    </section>
		</section>
	  )
    }
}

export default OurWork;