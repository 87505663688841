import React, {Component} from 'react';
import {Button, Col, Image, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FaAngleRight} from "react-icons/fa";
import './bannerStyles.css';

class PageBanner extends Component{
    render() {
	  const {boldHeading, heading, description, buttonLink, buttonText, imageUrl, desComponent=undefined} = this.props;
        return(
		<Row style={{minHeight: 400}} className={'page-top banner-outer'}>
		    <Col lg={5} md={5} sm={12} xs={12} className={'content-middle bannerHeader'}>
			  <h1>{boldHeading} <br/>{heading}</h1>
			  {desComponent ? desComponent :
			  <p className={'text-left'}>{description}</p>}
			  {buttonLink && <Link to={buttonLink} style={{textDecoration: 'none'}}><Button size={'lg'}>{buttonText} <FaAngleRight/></Button></Link>}
		    </Col>
		    <Col lg={7} md={7} sm={12} xs={12} className={'text-center content-middle'}>
			  <Image className={'image-center bannerImage'} fluid src={imageUrl} />
		    </Col>
		</Row>
	  )
    }
}

export default PageBanner;