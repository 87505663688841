import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import ProductItem from "../ProductGrid/ProductItem";


class ProductGrid extends Component{
    renderProductItems = () => {
	  const {products} = this.props;
	  const itemList = [];
	  products.length > 0 && products.forEach(product => {
		itemList.push(
		<Col lg={6} md={6} sm={12} xs={12} key={product.heading} className={'text-left product-col'}>
		    <ProductItem
			  src={product.src}
			  headerImage={product.headerImage}
			  heading={product.heading}
			  description={product.description}
			  technologies={product.technologies}
			  backEnd={product.backEnd}
			  link={product.link}
		    />
		</Col>)
	  })
	  return itemList;
    }
    render() {
        return(
		<Row>
		    {this.renderProductItems()}
		</Row>
	  )
    }
}
export default ProductGrid