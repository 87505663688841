const services = [
    {
        heading: 'Mobile Application Development',
        imageUrl: 'images/mobile-application.png',
        description: 'We build stunning, resource optimized mobile applications on Native and Hybrid platforms for Android, iOS and Amazon devices.'
    },{
        heading: 'Web Application Development',
        imageUrl: 'images/web-application.png',
        description: 'We build interactive and astonishing websites using the latest technologies'
    },{
        heading: 'API Development',
        imageUrl: 'images/api-dev.png',
        description: 'We are experts in developing APIs with Play and Node based frameworks. We design, program, deploy and maintain your API layer.'
    },{
        heading: 'UI Engineering',
        imageUrl: 'images/alexa-integration.png',
        description: 'Transform your website into a stunning, user-friendly masterpiece with our expert UI engineering services. Our team has the skills and experience to elevate your online presence and improve the overall user experience.'
    },{
        heading: 'MVP Product Development',
        imageUrl: 'images/rapid-mvp.png',
        description: 'With our MVP development you can confidently deploy your application within the shortest amount of time. Plus, we have exciting revenue models especially designed for start-ups.'
    },{
        heading: 'Team Augmentation',
        imageUrl: 'images/team-augmentation.png',
        description: 'Need to bring in extra resources for your project? Our team augmentation services, featuring skilled IT professionals from Sri Lanka, can help. Sri Lanka is known for its high-quality IT professionals, and our team is no exception. Contact us today to learn more about how we can support your project needs with our talented team of developers.'
    }
]

export default services;