import React, {Component} from 'react';
import {Button, Col, Container, Image, Modal, Row, Spinner} from "react-bootstrap";
import PageBanner from "../../components/PageBanner";
import ContactDetails from "../../components/ContactDetails";
import ContactForm from "../../components/ContactForm";
import {FaEnvelopeOpenText} from "react-icons/fa";
import axios from 'axios';

class ContactUs extends Component {
    defaultState={
	  name: '',
	  phone: '',
	  email: '',
	  subject: '',
	  message: '',
	  validName: undefined,
	  validPhone: undefined,
	  validEmail: undefined,
	  validSubject: undefined,
	  validMessage: undefined,
	  showModel: false,
	  loading: false
    }
    state = {
	  ...this.defaultState
    }

    constructor(props) {
	  super(props)
	  this.top = React.createRef()
    }

    componentDidMount() {
	  this.top.current.scrollTo(0, 0);
    }

	setPhoneNumber = (value) => {
		this.setState({phone: value});
		this.isValidPhone(value)
	}

    setFormDetails = (name, event) => {
	  const value = event.target.value;
	  switch (name) {
		case 'name':
		    this.setState({name: value});
		    this.isValidName(value)
		    break;
		case 'email':
		    this.setState({email: value});
		    this.isValidEmail(value);
		    break;
		case 'subject':
		    this.setState({subject: value});
		    this.isValidSubject(value);
		    break;
		case 'message':
		    this.setState({message: value});
		    this.isValidMessage(value);
		    break;
		default:
		    console.log('Nothing Changed!')
	  }
    }

    validateForm = () => {
	  const {name, email, phone, subject, message, validName, validPhone, validEmail, validSubject, validMessage} = this.state;
	  this.isValidName(name);
	  this.isValidPhone(phone)
	  this.isValidEmail(email);
	  this.isValidSubject(subject);
	  this.isValidMessage(message);
	  return validName && validPhone && validEmail && validSubject && validMessage;
    }

    isValidName = value => this.setState({validName: !!(value && value.length > 0)});
	isValidPhone = value => this.setState({validPhone: !!(value && value.length > 0)});
    // eslint-disable-next-line no-useless-escape
    // isValidPhone = value => this.setState({validPhone: !!(value && value.length >= 0 && value.match(/^(([\+]{1}[0-9]{1,3}[\ ]{1}[0-9]{1,2}[\ ]{1}[0-9]{4}[\ ]{1}[0-9]{4})|([0]{1}[0-9]{1}[\ ]{1}[0-9]{4}[\ ]{1}[0-9]{4})|([0]{1}[0-9]{1}[\-]{1}[0-9]{4}[\-]{1}[0-9]{4})|([\(]{1}[0]{1}[0-9]{1}[\)]{1}[\ ]{1}[0-9]{4}([\ ]|[\-]){1}[0-9]{4})|([0-9]{4}([\ ]|[\-])?[0-9]{4})|([0]{1}[0-9]{3}[\ ]{1}[0-9]{3}[\ ]{1}[0-9]{3})|([0]{1}[0-9]{9})|([\(]{1}[0-9]{3}[\)]{1}[\ ]{1}[0-9]{3}[\-]{1}[0-9]{4})|([0-9]{3}([\/]|[\-]){1}[0-9]{3}[\-]{1}[0-9]{4})|([1]{1}[\-]?[0-9]{3}([\/]|[\-]){1}[0-9]{3}[\-]{1}[0-9]{4})|([1]{1}[0-9]{9}[0-9]?)|([0-9]{3}[\.]{1}[0-9]{3}[\.]{1}[0-9]{4})|([\(]{1}[0-9]{3}[\)]{1}[0-9]{3}([\.]|[\-]){1}[0-9]{4}(([\ ]?(x|ext|extension)?)([\ ]?[0-9]{3,4}))?)|([1]{1}[\(]{1}[0-9]{3}[\)]{1}[0-9]{3}([\-]){1}[0-9]{4})|([\+]{1}[1]{1}[\ ]{1}[0-9]{3}[\.]{1}[0-9]{3}[\-]{1}[0-9]{4})|([\+]{1}[1]{1}[\ ]?[\(]{1}[0-9]{3}[\)]{1}[0-9]{3}[\-]{1}[0-9]{4}))$/)) ? true : value.length === 0});
    isValidEmail = value => this.setState({validEmail: !!(value && value.length > 0 && value.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/))});
    isValidSubject = value => this.setState({validSubject: !!(value && value.length > 0)});
    isValidMessage = value => this.setState({validMessage: !!(value && value.length > 0)});

    onSubmit = async () => {
	  if (!this.validateForm()) return;
	  this.setState({loading: true});
	  const {name, phone, email, subject, message} = this.state;
	// http://localhost:3001/api/services/email/bulk
	  await axios.post(' https://services.incylabs.com/api/services/email/bulk', {
		"service" : "zoho",
		"account" : "fooder",
		"templateId" : "CONTACT_US",
		"emailRequestList" : [{
			"key": "12314141241",
			"body": {
				"from" : "Incy Labs <fooder@incylabs.com>",
				"to": "incylabs@gmail.com",
				"replyTo" : "No Reply",
				"subject": "Contact us",
				"data": {
					"name" : name,
					"phone" : phone,
					"email" : email,
					"subject" : subject,
					"message" : message,
				}
			}
		}]
	}).then(res => {
	      if (res.status === 200){
		    this.setState({loading: false, showModel: true})
		} else {
		    this.setState({loading: false});
		    console.log("Error sending email", res);
		}
	  })
    }
    handleModelAction = () => {
	  // eslint-disable-next-line no-restricted-globals
        location.reload(true)
	  this.setState({...this.defaultState})
    }

    render() {
	  const {name, showModel, loading} = this.state;
	  return (
		<section ref={this.top}>
		    <Container>
			  <PageBanner
				boldHeading={'Contact Us'}
				heading={'We love talking with you.'}
				description={'We are here to help and answer any question you might have on our services and ' +
				'products. We look forward to hearing from you.'}
				buttonLink={null}
				buttonText={null}
				imageUrl={'images/contact-us.png'}
			  />
		    </Container>
		    <section className={'section-outer'} style={{borderBottom: 0}}>
			  <Container>
				<Row style={{alignItems: 'center'}}>
				    <Col lg={4} md={4} sm={12} xs={12} style={{marginBottom: 30}} className={'right-border'}>
					  <ContactDetails/>
				    </Col>
				    <Col lg={8} md={8} sm={12} xs={12} style={{marginBottom: 30}}>
					  {loading ? <Spinner animation="border" /> :
					  <ContactForm onChange={this.setFormDetails} onSubmit={this.onSubmit} setPhoneNumber={this.setPhoneNumber} data={this.state}/>}
					  {/*<Image src={"images/logo-main.png"} lg={"auto"} md={"auto"} style={{width: '75%'}}/>*/}
				    </Col>
				</Row>
			  </Container>
		    </section>
		    <Modal
			  show={showModel}
			  size="sm"
			  centered
			  className={'contact-model'}
			  onHide={() => this.handleModelAction()}
		    >
			  <Modal.Body>
				<Row>
				    <Col lg={2} className={'model-content-icon'}>
					  <FaEnvelopeOpenText/>
				    </Col>
				    <Col lg={10}>
					  <h4 style={{paddingTop: 20}}>Hi, {name}!</h4>
					  <p>
						We've received your message.. We will contact you soon!
					  </p>
					  <Button style={{float: 'right'}} onClick={() => this.handleModelAction()}>Okay</Button>
				    </Col>
				</Row>
			  </Modal.Body>
		    </Modal>
		</section>
	  )
    }
}

export default ContactUs;