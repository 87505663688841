import React, { Component } from "react";
import {Col, Form} from 'react-bootstrap';

class ApplicationInformation extends Component{
    render(){
        const { onChange, data } = this.props;
        const { purpose, features ,budget, integration, sampleapp, experience, wireframes, havedesign, provideimages, anyaboutapp, logoavailable, validbudget, validlogoavailable, validwireframes } = data;

        return(
            <Form className={'quotation-form'}>
                <h4 style={{textAlign: 'left', marginTop: '15px'}}>Application Information</h4>
                <Form.Row style={{marginTop: '10px'}}>
                    <Form.Group as={Col} lg={12} md={12} xs={12} sm={12} controlId="formGridName">
                        <Form.Label>What is the purpose of the mobile app?</Form.Label>
                        <Form.Control
                            as="textarea" rows="2"
                            placeholder=""
                            onChange={(e) => onChange('purpose', e)} value={purpose}/>
                    </Form.Group>
                </Form.Row>
                <Form.Row style={{marginTop: '10px'}}>
                    <Form.Group as={Col} lg={12} md={12} xs={12} sm={12} controlId="formGridName">
                        <Form.Label>What features do you envision for the mobile app?</Form.Label>
                        <Form.Control
                            as="textarea" rows="2"
                            placeholder=""
                            onChange={(e) => onChange('features', e)} value={features}/>
                    </Form.Group>
                </Form.Row>
                <Form.Row style={{marginTop: '10px'}}>
                    <Form.Group as={Col} lg={4} md={6} xs={12} sm={12} controlId="formGridName">
                        <Form.Label>What is your budget for your website project? *</Form.Label><br/>
                        <Form.Control as="select" value={budget} isValid={validbudget} isInvalid={validbudget !== undefined && !validbudget} 
                            onChange={(e) => onChange('budget', e)}>
                            <option selected value="0">--Please choose an option --</option>
                            <option value="$1,500-$3,000">$1,500-$3,000</option>
                            <option value="$3,000-$6,000">$3,000-$6,000</option>
                            <option value="$6,000-$10,000">$6,000-$10,000</option>
                            <option value="Over $10,000">Over $10,000</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} lg={8} md={6} xs={12} sm={12} controlId="formGridName">
                        <Form.Label>Will the mobile app need to integrate with any existing systems or platforms?</Form.Label>
                        <Form.Control as='select' value={integration}
                            onChange={(e) => onChange('integration', e)} >
                            <option selected value='0'>--Please choose an option --</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </Form.Control>
                    </Form.Group>
                </Form.Row>   

                <Form.Row style={{marginTop: '10px'}}>
                    <Form.Group as={Col} lg={12} md={12} xs={12} sm={12} controlId="formGridName">
                        <Form.Label>Do you have any examples of mobile apps that you like the look and feel of?</Form.Label>
                        <Form.Control
                            as="textarea" rows="2"
                            placeholder=""
                            onChange={(e) => onChange('sampleapp', e)} value={sampleapp}/>
                    </Form.Group>
                </Form.Row>

                <Form.Row style={{marginTop: '10px'}}>
                    <Form.Group as={Col} lg={12} md={12} xs={12} sm={12} controlId="formGridName">
                        <Form.Label>Have you worked with a mobile app development team before? If so, what was your experience?</Form.Label>
                        <Form.Control
                            as="textarea" rows="2"
                            placeholder=""
                            onChange={(e) => onChange('experience', e)} value={experience}/>
                    </Form.Group>
                </Form.Row>

                <h4 style={{textAlign: 'left', marginTop: '15px'}}>Design & Content</h4>
                {/* Do you have a business logo? */}
                <Form.Row style={{marginTop: '10px'}}>
                    <Form.Group as={Col} lg={6} md={6} xs={12} sm={12} controlId="formGridName">
                        <Form.Label>Do you have a logo? *</Form.Label><br/>
                        <Form.Control as='select' value={logoavailable} isValid={validlogoavailable} isInvalid={validlogoavailable !== undefined && !validlogoavailable}
                            onChange={(e) => onChange('logoavailable', e)} >
                            <option selected value='0'>--Please choose an option --</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} lg={6} md={6} xs={12} sm={12} controlId="formGridName">
                        <Form.Label>Do you have wire-frames of the app? *</Form.Label><br/>
                        <Form.Control as='select' value={wireframes}  isValid={validwireframes} isInvalid={validwireframes !== undefined && !validwireframes}
                            onChange={(e) => onChange('wireframes', e)}>
                            <option selected value='0'>--Please choose an option --</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </Form.Control>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} lg={6} md={6} xs={12} sm={12} controlId="formGridName">
                        <Form.Label>Do you have high-fidelity design finalized for your app?</Form.Label><br/>
                        <Form.Control as='select' value={havedesign} 
                            onChange={(e) => onChange('havedesign', e)}>
                            <option selected value='0'>--Please choose an option --</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} lg={6} md={6} xs={12} sm={12} controlId="formGridName">
                        <Form.Label>Will you be providing required images and other visual elements?</Form.Label><br/>
                        <Form.Control as='select' value={provideimages} 
                            onChange={(e) => onChange('provideimages', e)}>
                            <option selected value='0'>--Please choose an option --</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </Form.Control>
                    </Form.Group>
                </Form.Row>

                <Form.Row style={{marginTop: '10px'}}>
                    <Form.Group as={Col} lg={12} md={12} xs={12} sm={12} controlId="formGridName">
                        <Form.Label>Is there anything else you would like us to know about your requirement?</Form.Label>
                        <Form.Control
                            as="textarea" rows="3"
                            placeholder=""
                            onChange={(e) => onChange('anyaboutapp', e)} value={anyaboutapp}/>
                    </Form.Group>
                </Form.Row>
            </Form>
        )
    }
}

export default ApplicationInformation;