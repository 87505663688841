import React from 'react';
import './App.css';
import Root from './Root'

function App() {
  return (
    <div className="App">
      <Root/>
    </div>
  );
}

export default App;
