import React, {Component} from 'react';
import {FaEnvelope, FaFacebook, FaInstagram, FaLinkedinIn, FaMapMarker, FaPhone, FaTwitter} from "react-icons/fa";
import './contactDetailStyles.css'
import {Button, Col} from "react-bootstrap";

class ContactDetails extends Component {
    render() {
	  return (
		<section className={'contact-details text-left'}>
		    <h1 className={'section-title text-left'}>Get in touch</h1>
		    <Col style={{textAlign: 'left', display: '-webkit-box', padding: 0}}>
				<div>
					<h4 style={{marginRight: 10}}>Sri Lanka</h4>
					<p style={{paddingTop: 1}}> 
						<FaMapMarker style={{marginRight: 10}}/>
						265/2,<br/>
						<div style={{marginLeft: '24px'}}>
							Sri Saddharma Mawatha,<br/>
							Colombo 10,<br/>
							Sri Lanka.
						</div>
						<a href={'tel:+94776994049'} style={{textDecoration: 'none', color: '#212529'}}><FaPhone style={{marginRight: 15}}/> +94 776 99 40 49</a>
					</p>
					<br/>
					<h4 style={{marginRight: 10}}>Sweden</h4>
					<p style={{paddingTop: 1}}> 
						<FaMapMarker style={{marginRight: 10}}/>Nedersta Alle 37<br/>
						<div style={{marginLeft: '24px'}}>
							13739,<br/>
							Västerhanninge,<br/>
							Sweden.
						</div>
					</p>
				</div>
		    </Col>
		    {/* <a href={'tel:+94776994049'} style={{textDecoration: 'none', color: '#212529'}}><FaPhone
			  style={{marginRight: 15}}/> +94 776 99 40 49</a> */}
		    <a href={'mailto:hello@incylabs.com'} style={{textDecoration: 'none', color: '#212529'}}><FaEnvelope
			  style={{marginRight: 15}}/> hello@incylabs.com</a>

		    <Col style={{textAlign: 'left', paddingTop: 20, marginBottom: 20, paddingLeft: 0, paddingRight: 0}}>
			  <Button style={{borderRadius: '100%' , marginRight: 10}} href={"https://www.facebook.com/incylabs"} target={'_blank'}><FaFacebook/></Button>
			  <Button style={{borderRadius: '100%', marginRight: 10}} href={'https://www.linkedin.com/company/incy-private-ltd/'} target={'_blank'}><FaLinkedinIn/></Button>
			  <Button style={{borderRadius: '100%', marginRight: 10}} href={'https://www.instagram.com/incylabs'} target={'_blank'}><FaInstagram/></Button>
			  <Button style={{borderRadius: '100%', marginRight: 10}} href={'https://twitter.com/IncyLabs'} target={'_blank'}><FaTwitter/></Button>
		    </Col>
		</section>
	  )
    }
}

export default ContactDetails;