import React, { Component } from "react"
import {Col, Form} from "react-bootstrap";

class QuotationForm extends Component {
    render(){
        const {onChange, data} = this.props;
        const {industry,budget,websiteavailable, webname1,webname2,webname3,logoavailable,
            sellproducts,slider,gallary,portfolio,testimonial,team,calandar, youtube, paypal,login,blog,contentprovide,imageprovide, purpose,training,updateweb,contacttime,
            validindustry,validbudget, validwebsiteavailable,validwebname1,validlogoavailable,validsellproducts, validblog, validcontentprovide, validimageprovide, validtraining, validupdateweb} = data;

        return (
            <Form className={'quotation-form'}>
                <h4 style={{textAlign: 'left', marginTop: '15px'}}>Website Information</h4>
                <Form.Row style={{marginTop: '10px'}}>
                    <Form.Group as={Col} lg={4} md={6} xs={12} sm={12} controlId="formGridSubject">
                        <Form.Label>Type of Business or Industry? *</Form.Label>
                        <Form.Control
                            type="text" placeholder="" onChange={(e) => onChange('industry', e)}
                            isValid={validindustry} isInvalid={validindustry !== undefined && !validindustry} value={industry}/>
                    </Form.Group>
                    <Form.Group as={Col} lg={4} md={6} xs={12} sm={12} controlId="formGridName">
                        <Form.Label>What is your budget for your website project? *</Form.Label><br/>
                        <Form.Control as="select" value={budget} isValid={validbudget} isInvalid={validbudget !== undefined && !validbudget} 
                            onChange={(e) => onChange('budget', e)}>
                            <option selected value="0">--Please choose an option --</option>
                            <option value="$1,500-$3,000">$1,500-$3,000</option>
                            <option value="$3,000-$6,000">$3,000-$6,000</option>
                            <option value="$6,000-$10,000">$6,000-$10,000</option>
                            <option value="Over $10,000">Over $10,000</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} lg={4} md={6} xs={12} sm={12} controlId="formGridName">
                        <Form.Label>Do you currently have a website? *</Form.Label><br/>
                        <Form.Control as='select' value={websiteavailable} isValid={validwebsiteavailable} isInvalid={validwebsiteavailable !== undefined && !validwebsiteavailable}
                            onChange={(e) => onChange('websiteavailable', e)}>
                            <option selected value="0">--Please choose an option --</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </Form.Control>
                    </Form.Group>
                </Form.Row>    

                <Form.Row style={{marginTop: '10px'}}>
                    <Form.Label>We Provide up to 3 website examples in your industry and describe what you like about each.</Form.Label>
                </Form.Row>

                <Form.Row style={{marginTop: '10px'}}>
                    <Form.Group as={Col} lg={12} md={12} xs={12} sm={12} controlId="formGridName">
                        <Form.Label>Website #1 *</Form.Label>
                        <Form.Control
                            as="textarea" rows="2"
                            placeholder=""
                            onChange={(e) => onChange('webname1', e)} isValid={validwebname1}
                            isInvalid={validwebname1 !== undefined && !validwebname1} value={webname1}/>
                    </Form.Group>
                </Form.Row>

                <Form.Row style={{marginTop: '10px'}}>
                    <Form.Group as={Col} lg={12} md={12} xs={12} sm={12} controlId="formGridName">
                        <Form.Label>Website #2</Form.Label>
                        <Form.Control
                            as="textarea" rows="2"
                            placeholder=""
                            onChange={(e) => onChange('webname2', e)}
                             value={webname2}/>
                    </Form.Group>
                </Form.Row>

                <Form.Row style={{marginTop: '10px'}}>
                    <Form.Group as={Col} lg={12} md={12} xs={12} sm={12} controlId="formGridName">
                        <Form.Label>Website #3</Form.Label>
                        <Form.Control
                            as="textarea" rows="2"
                            placeholder=""
                            onChange={(e) => onChange('webname3', e)} value={webname3}/>
                    </Form.Group>
                </Form.Row>

                <h4 style={{textAlign: 'left', marginTop: '15px'}}>Design & Content</h4>
                {/* Do you have a business logo? */}
                <Form.Row style={{marginTop: '10px'}}>
                    <Form.Group as={Col} lg={6} md={6} xs={12} sm={12} controlId="formGridName">
                        <Form.Label>Do you have a business logo? *</Form.Label><br/>
                        <Form.Control as='select' value={logoavailable} isValid={validlogoavailable} isInvalid={validlogoavailable !== undefined && !validlogoavailable}
                            onChange={(e) => onChange('logoavailable', e)} >
                            <option selected value='0'>--Please choose an option --</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} lg={6} md={6} xs={12} sm={12} controlId="formGridName">
                        <Form.Label>Will you be selling products on your website? *</Form.Label><br/>
                        <Form.Control as='select' value={sellproducts}  isValid={validsellproducts} isInvalid={validsellproducts !== undefined && !validsellproducts}
                            onChange={(e) => onChange('sellproducts', e)}>
                            <option selected value='0'>--Please choose an option --</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </Form.Control>
                    </Form.Group>
                </Form.Row>

                <div style={{'padding': '20px'}}>
                    <Form.Row style={{marginTop: '10px'}}>
                        <Form.Group as={Col} lg={6} md={6} xs={12} sm={12} controlId="formGridName">
                            <Form.Label>What type of features do you need?</Form.Label><br/>
                            <div key={`slider'}`} className="mb-3">
                                <Form.Check 
                                    type={'checkbox'}
                                    id={`slider`}
                                    label={'Slider'}
                                    value= {slider}
                                    onChange={(e) => onChange('slider', e)}
                                />
                            </div>
                            <div key={`gallary`} className="mb-3">
                                <Form.Check 
                                    type={'checkbox'}
                                    id={`gallary`}
                                    label={`Gallery`}
                                    value= {gallary}
                                    onChange={(e) => onChange('gallary', e)}
                                />
                            </div>
                            <div key={`portfolio`} className="mb-3">
                                <Form.Check 
                                    type={'checkbox'}
                                    id={`portfolio`}
                                    label={`Portfolio`}
                                    value= {portfolio}
                                    onChange={(e) => onChange('portfolio', e)}
                                />
                            </div>
                            <div key={`testimonial`} className="mb-3">
                                <Form.Check 
                                    type={'checkbox'}
                                    id={`testimonial`}
                                    label={`Testimonials`}
                                    value= {testimonial}
                                    onChange={(e) => onChange('testimonial', e)}
                                />
                            </div>
                            <div key={`team`} className="mb-3">
                                <Form.Check 
                                    type={'checkbox'}
                                    id={`team`}
                                    label={`Team/Employee Page`}
                                    value= {team}
                                    onChange={(e) => onChange('team', e)}
                                />
                            </div>
                            <div key={`calendar`} className="mb-3">
                                <Form.Check 
                                    type={'checkbox'}
                                    id={`calendar`}
                                    label={`Event Calendar`}
                                    value= {calandar}
                                    onChange={(e) => onChange('calandar', e)}
                                />
                            </div>
                            <div key={`youtube`} className="mb-3">
                                <Form.Check 
                                    type={'checkbox'}
                                    id={`youtube`}
                                    label={`Youtube/Vimeo Videos`}
                                    value= {youtube}
                                    onChange={(e) => onChange('youtube', e)}
                                />
                            </div>
                            <div key={`paypal`} className="mb-3">
                                <Form.Check 
                                    type={'checkbox'}
                                    id={`paypal`}
                                    label={`Paypal`}
                                    value= {paypal}
                                    onChange={(e) => onChange('paypal', e)}
                                />
                            </div>
                            <div key={`login`} className="mb-3">
                                <Form.Check 
                                    type={'checkbox'}
                                    id={`login`}
                                    onChange={(e) => onChange('login', e)}
                                    label={`Login Portal/Client Area`}
                                    value= {login}
                                />
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} lg={6} md={6} xs={12} sm={12} controlId="formGridName">
                            <Form.Label style={{marginTop: '20px'}}>Do you want a blog on your website? *</Form.Label><br/>
                            <Form.Control as='select' value={blog} isValid={validblog} isInvalid={validblog !== undefined && !validblog}
                                onChange={(e) => onChange('blog', e)} >
                                <option selected value='0'>--Please choose an option --</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Control>

                            <Form.Label style={{marginTop: '20px'}}>Will you be providing the written content for the website? *</Form.Label><br/>
                            <Form.Control as='select' value={contentprovide}  isValid={validcontentprovide} isInvalid={validcontentprovide !== undefined && !validcontentprovide} 
                                onChange={(e) => onChange('contentprovide', e)}>
                                <option selected value='0'>--Please choose an option --</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Control>
                        
                            <Form.Label style={{marginTop: '20px'}}>Will you be providing the images for the website? *</Form.Label><br/>
                            <Form.Control as='select' value={imageprovide} isValid={validimageprovide} isInvalid={validimageprovide !== undefined && !validimageprovide}
                                onChange={(e) => onChange('imageprovide', e)} >
                                <option selected value='0'>--Please choose an option --</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                </div>

                <h4 style={{textAlign: 'left', marginTop: '15px'}}>Objectives</h4>
                <Form.Row style={{marginTop: '10px'}}>
                    <Form.Group as={Col} lg={12} md={12} xs={12} sm={12} controlId="formGridName">
                        <Form.Label>What is the purpose of your website?</Form.Label>
                        <Form.Control
                            as="textarea" rows="3"
                            placeholder=""
                            onChange={(e) => onChange('purpose', e)} value={purpose}/>
                    </Form.Group>
                </Form.Row>

                <h4 style={{textAlign: 'left', marginTop: '15px'}}>Training & Additional Questions</h4>
                <Form.Row style={{marginTop: '10px'}}>
                    <Form.Group as={Col} lg={8} md={8} xs={12} sm={12} controlId="formGridName">
                        <Form.Label style={{marginTop: '20px'}}>Do you intend to update the website content yourself? *</Form.Label><br/>
                        <Form.Control as='select' value={updateweb} isValid={validupdateweb} isInvalid={validupdateweb !== undefined && !validupdateweb}
                            onChange={(e) => onChange('updateweb', e)}>
                            <option selected value='0'>--Please choose an option --</option>
                            <option value="Yes">Yes</option>
                            <option value="No. I need continuous support.">No. I need continuous support.</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} lg={4} md={6} xs={12} sm={12} controlId="formGridName">
                        <Form.Label style={{marginTop: '20px'}}>What is the best time to contact you?</Form.Label><br/>
                        <Form.Control as='select' value={contacttime} onChange={(e) => onChange('contacttime', e)}>
                            <option selected value='0'>--Please choose an option --</option>
                            <option value="Morning">Morning</option>
                            <option value="Afternoon">Afternoon</option>
                            <option value="Evening">Evening</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} lg={6} md={6} xs={12} sm={12} controlId="formGridName">
                        <Form.Label style={{marginTop: '20px'}}>Will you or your staff require training? *</Form.Label><br/>
                        <Form.Control as='select' value={training} isValid={validtraining} isInvalid={validtraining !== undefined && !validtraining} 
                            onChange={(e) => onChange('training', e)}>
                            <option selected value='0'>--Please choose an option --</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
            </Form>
        )
    }
}

export default QuotationForm;