import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import Avatar from "./Avatar";

class Team extends Component{
    renderTeam = () => {
	  const {team} = this.props;
	  const teamList = [];
	  team && team.length > 0 && team.forEach(member => {
	      teamList.push(<Col lg={4} md={4} sm={12} xs={12}>
		    <Avatar profile={member}/>
		</Col>)
	  })
	  return teamList;
    }
    render() {

        return(
		<Row className={'common-space'}>
		    {this.renderTeam()}
		</Row>
	  )
    }
}
export default Team;