import React, {Component} from 'react';
import {Image} from "react-bootstrap";
import './technologyItemStyles.css'

class TechnologyItem extends Component{
    render() {
        const {src} = this.props;
        return(
            <div className={'technologyItem'}>
		    <Image fluid src={src}/><br/>
		</div>
	  )
    }
}
export default TechnologyItem;