import React, { Component } from 'react';
import {Container} from "react-bootstrap";
import PageBanner from "../../components/PageBanner";
import Team from "../../components/Team";
import team from "../../Data/team";

class About extends Component{
    constructor(props) {
    super(props)
    this.top = React.createRef()
}
    componentDidMount() {
	  this.top.current.scrollTo(0, 0);
    }
    render() {
        return(
            <section ref={this.top}>
		    <Container>
			  <PageBanner
				boldHeading={'About Us'}
				heading={null}
				description={"Incy was founded in 2015 by ranGa Illeperuma & Chinthaka Amarabandu, who are close " +
				"friends from childhood. They shared a passion for building great software solutions and formed " +
				"Incy along with Mr Sidath Fernando, The Director of Incy and Managing Director of VDP Holdings " +
				"Pvt. Ltd. Incy has today, grown into a small team of fun-loving and an equally passionate group " +
				"of indviduals, who shares the Incy's vision for providing unparrelled App development solutions."}
				buttonLink={null}
				buttonText={null}
				imageUrl={'images/about-us.png'}
			  />

			  {/*<div>*/}
				{/*<h1 className={'section-title'}>Our Team</h1>*/}
				{/*<div style={{width: '10%', borderBottom: '1px solid #000000', margin: '20px auto'}}/>*/}
				{/*<Team team={team}/>*/}
			  {/*</div>*/}

		    </Container>
		</section>
	  )
    }
}
export default About;