import React, {Component} from 'react';

class Title extends Component{
    render() {
        const {title} = this.props;
	  return(
		<h1 className={'section-title text-left'}>{title}</h1>
	  )
    }
}

class TitleUnderline extends Component{
    render() {
	  return(
		<div style={{ borderBottom: '3px solid #000000', width: 50, marginBottom: 30}}/>
	  )
    }
}

export {Title, TitleUnderline};