import React, {useState, useEffect} from 'react';
import {Button, Col, Container, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FaEnvelopeOpenText} from "react-icons/fa";
import axios from 'axios';
import QuotationForm from '../../components/QuotationForm';
import ContactInformation from '../../components/QuotationForm/contactInformation';
import ApplicationInformation from '../../components/QuotationForm/applicationInformation';
import FigmaDesignInformation from '../../components/QuotationForm/figmaDesignInformation';
import {Switch, useParams, useHistory} from 'react-router-dom';


const Quotation = (props) => {
    const [firstname, setFirstName] = useState('')
    const [lastname, setLastName] = useState('')
    const [businessname, setBusinessName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [industry, setIndustry] = useState('')
    const [budget, setBudget] = useState('')
    const [websiteavailable, setWebsiteAvailable] = useState('')
    const [webname1, setWebName1] = useState('')
    const [webname2, setWebName2] = useState('')
    const [webname3, setWebName3] = useState('')
    const [logoavailable, setLogoAvailable] = useState('')
    const [sellproducts, setSellProducts] = useState('')
    const [slider, setSlider] = useState('')
    const [gallary, setGallary] = useState('')
    const [portfolio, setPortfolio] = useState('')
    const [testimonial, setTestimonial] = useState('')
    const [team, setTeam] = useState('')
    const [calandar, setCalandar] = useState('')
    const [youtube, setYoutube] = useState('')
    const [paypal, setPaypal] = useState('')
    const [login, setLogin] = useState('')
    const [blog, setBlog] = useState('')
    const [contentprovide, setContentProvide] = useState('')
    const [imageprovide, setImageProvide] = useState('')
    const [purpose, setPurpose] = useState('')
    const [training, setTraining] = useState('')
    const [updateweb, setUpdateWeb] = useState('')
    const [contacttime, setContactTime] = useState('')
    const [type, setType] = useState('')
    const [timeline, setTimeLine] = useState('')
    const [screencount, setScreenCount] = useState('')
    const [features, setFeatures] = useState('')
    const [integration, setIntegration] = useState('')
    const [sampleapp, setSampleApp] = useState('')
    const [experience, setExperience] = useState('')
    const [wireframes, setWireframes] = useState('')
    const [havedesign, setHaveDesign] = useState('')
    const [provideimages, setProvideImages] = useState('')
    const [anyaboutapp, setAnyAboutApp] = useState('')
    const [validfirstname, setValidFirstName] = useState(undefined)
    const [validlastname, setValidLastName] = useState(undefined)
    const [validphone, setValidPhone] = useState(undefined)
    const [validemail, setValidEmail] = useState(undefined)
    const [validindustry, setValidIndustry] = useState(undefined)
    const [validbudget, setValidBudget] = useState(undefined)
    const [validwebsiteavailable, setValidWebsiteAvailable] = useState(undefined)
    const [validwebname1, setValidWebName1] = useState(undefined)
    const [validlogoavailable, setValidLogoAvailable] = useState(undefined)
    const [validsellproducts, setValidSellProducts] = useState(undefined)
    const [validblog, setValidBlog] = useState(undefined)
    const [validcontentprovide, setValidContentProvide] = useState(undefined)
    const [validimageprovide, setValidImageProvide] = useState(undefined)
    const [validtraining, setValidTraining] = useState(undefined)
    const [validupdateweb, setValidUpdateWeb] = useState(undefined)
    const [validscreencount, setValidScreenCount] = useState(undefined)
    const [validprovideimages, setValidProvideImage] = useState(undefined)
    const [validwireframes, setValidWireframes] = useState(undefined)
    const [validtimeline, setValidTimeline] = useState(undefined)
    const [showModel, setShowModel] = useState(false)
    const [loading, setLoading] = useState(false)

    const data = {firstname, lastname, businessname, email, phone, industry, budget, websiteavailable, webname1, webname2, webname3, logoavailable, 
            sellproducts,slider, gallary, portfolio, portfolio, testimonial, team, calandar, youtube, paypal, login, blog, contentprovide, imageprovide, purpose, training, updateweb, contacttime, type,
            timeline, screencount, features, integration , sampleapp , experience ,wireframes , havedesign , provideimages, anyaboutapp, validfirstname, validlastname, validphone,validemail,validindustry,validbudget,
            validwebsiteavailable,validwebname1,validlogoavailable,validsellproducts,validblog,validcontentprovide,validimageprovide,validtraining,validupdateweb,validscreencount,validprovideimages,validwireframes}
    

    const {formType} = useParams();
    const history = useHistory();

    useEffect(() => {
        setType(formType)
	    // top.current.scrollTo(0, 0);
    }, [])

    const onChange = (name, event) => {
        const value = event.target.value;
        setFormFields(name, event);
    }

    const setPhoneNumber = (value) => {
        setPhone(value);
        validateFormFields("phone", value)
    }

    const setFormFields = (name, event) => {
        const value = event.target.value;
        const chkValue = event.target.checked;

        switch (name) {
            case "firstname":
                setFirstName(value);
                validateFormFields(name, value)
                break;

            case "lastname":
                setLastName(value);
                validateFormFields(name, value)
                break;

            case "businessname":
                setBusinessName(value);
                break;

            case "email":
                setEmail(value);
                validateFormFields(name, value)
                break;

            case "industry":
                setIndustry(value);
                validateFormFields(name, value)
                break;

            case "budget":
                setBudget(value);
                setValidBudget(isValidOption(value));
                break;

            case "websiteavailable":
                setWebsiteAvailable(value);
                validateFormFields(name, value)
                break;
            
            case "webname1":
                setWebName1(value);
                validateFormFields(name, value)
                break;

            case "webname2":
                setWebName2(value);
                break;

            case "webname3":
                setWebName3(value);
                break;

            case "logoavailable":
                setLogoAvailable(value);
                validateFormFields(name, value)
                break;

            case "sellproducts":
                setSellProducts(value);
                validateFormFields(name, value)
                break;

            case "slider":
                setSlider(chkValue);
                break;

            case "gallary":
                setGallary(chkValue);
                break;

            case "portfolio":
                setPortfolio(chkValue);
                break;

            case "testimonial":
                setTestimonial(chkValue);
                break;

            case "team":
                setTeam(chkValue);
                break;

            case "calandar":
                setCalandar(chkValue);
                break;

            case "youtube":
                setYoutube(chkValue);
                break;

            case "paypal":
                setPaypal(chkValue);
                break;

            case "login":
                setLogin(chkValue);
                break;
            
            case "blog":
                setBlog(value);
                validateFormFields(name, value)
                break;

            case "contentprovide":
                setContentProvide(value);
                validateFormFields(name, value)
                break;

            case "imageprovide":
                setImageProvide(value);
                validateFormFields(name, value)
                break;

            case "purpose":
                setPurpose(value);
                break;

            case "training":
                setTraining(value);
                validateFormFields(name, value)
                break;

            case "updateweb":
                setUpdateWeb(value);
                validateFormFields(name, value)
                break;

            case "contacttime":
                setContactTime(value);
                break;

            case "type":
                setType(value);
                history.push(`${value}`)
                break;

            case "timeline":
                setTimeLine(value);
                break; 

            case "screencount":
                setScreenCount(value);
                validateFormFields(name, value)
                break; 

            case "features":
                setFeatures(value);
                break; 
        
            case "integration":
                setIntegration(value);
                break; 

            case "sampleapp":
                setSampleApp(value);
                break; 

            case "experience":
                setExperience(value);
                break; 

            case "wireframes":
                setWireframes(value);
                validateFormFields(name, value)
                break; 

            case "havedesign":
                setHaveDesign(value);
                break; 

            case "provideimages":
                setProvideImages(value);
                validateFormFields(name, value)
                break; 

            case "anyaboutapp":
                setAnyAboutApp(value);
                break; 
            default:
                break;
        }
    }

    const validateFormFields = (name, value) => {

        switch (name) {
            case "firstname":
                setValidFirstName(isValidName(value));
                break;

            case "lastname":
                setValidLastName(isValidName(value));
                break;

            case "email":
                setValidEmail(isValidEmail(value));
                break;

            case "phone":
                setValidPhone(isValidName(value));
                break;

            case "industry":
                setValidIndustry(isValidName(value))
                break;

            case "budget":
                setValidBudget(isValidOption(value));
                break;

            case "websiteavailable":
                setValidWebsiteAvailable(isValidOption(value));
                break;
            
            case "webname1":
                setValidWebName1(isValidName(value));
                break;

            case "logoavailable":
                setValidLogoAvailable(isValidOption(value));
                break;

            case "sellproducts":
                setValidSellProducts(isValidOption(value));
                break;

            case "contentprovide":
                setValidContentProvide(isValidOption(value));
                break;

            case "imageprovide":
                setValidImageProvide(isValidOption(value));
                break;

            case "training":
                setValidTraining(isValidOption(value));
                break;

            case "updateweb":
                setValidUpdateWeb(isValidOption(value));
                break;

            case "screencount":
                setValidScreenCount(isValidName(value));
                break; 

            case "wireframes":
                setValidWireframes(isValidOption(value));
                break; 

            case "provideimages":
                setValidProvideImage(isValidName(value));
                break; 

            default:
                break;
        }
    }

    const clearForm = () => {
        setFirstName('')
        setLastName('')
        setBusinessName('')
        setEmail('')
        setPhone('')
        setIndustry('')
        setBudget('')
        setWebsiteAvailable('')
        setWebName1('')
        setWebName2('')
        setWebName3('')
        setLogoAvailable('')
        setSellProducts('')
        setSlider('')
        setGallary('')
        setPortfolio('')
        setTestimonial('')
        setTeam('')
        setCalandar('')
        setYoutube('')
        setPaypal('')
        setLogin('')
        setBlog('')
        setContentProvide('')
        setImageProvide('')
        setPurpose('')
        setTraining('')
        setUpdateWeb('')
        setContactTime('')
        setType('')
        setTimeLine('')
        setScreenCount('')
        setFeatures('')
        setIntegration('')
        setSampleApp('')
        setExperience('')
        setWireframes('')
        setHaveDesign('')
        setProvideImages('')
        setAnyAboutApp('')
        setValidFirstName(undefined)
        setValidLastName(undefined)
        setValidPhone(undefined)
        setValidEmail(undefined)
        setValidIndustry(undefined)
        setValidBudget(undefined)
        setValidWebsiteAvailable(undefined)
        setValidWebName1(undefined)
        setValidLogoAvailable(undefined)
        setValidSellProducts(undefined)
        setValidBlog(undefined)
        setValidContentProvide(undefined)
        setValidImageProvide(undefined)
        setValidTraining(undefined)
        setValidUpdateWeb(undefined)
        setValidScreenCount(undefined)
        setValidProvideImage(undefined)
        setValidWireframes(undefined)
        setValidTimeline(undefined)
    }

    const isValidName = (value) => !!([value] && value.length > 0);
    const isValidPhone = (value) => !!([value] && value.length > 0 && value.match(/^[+]{0,1}[1-9]{0,3}(([\+]{1}[0-9]{1,3}[\ ]{1}[0-9]{1,2}[\ ]{1}[0-9]{4}[\ ]{1}[0-9]{4})|([0]{1}[0-9]{1}[\ ]{1}[0-9]{4}[\ ]{1}[0-9]{4})|([0]{1}[0-9]{1}[\-]{1}[0-9]{4}[\-]{1}[0-9]{4})|([\(]{1}[0]{1}[0-9]{1}[\)]{1}[\ ]{1}[0-9]{4}([\ ]|[\-]){1}[0-9]{4})|([0-9]{4}([\ ]|[\-])?[0-9]{4})|([0]{1}[0-9]{3}[\ ]{1}[0-9]{3}[\ ]{1}[0-9]{3})|([0]{1}[0-9]{9})|([\(]{1}[0-9]{3}[\)]{1}[\ ]{1}[0-9]{3}[\-]{1}[0-9]{4})|([0-9]{3}([\/]|[\-]){1}[0-9]{3}[\-]{1}[0-9]{4})|([1]{1}[\-]?[0-9]{3}([\/]|[\-]){1}[0-9]{3}[\-]{1}[0-9]{4})|([1]{1}[0-9]{9}[0-9]?)|([0-9]{3}[\.]{1}[0-9]{3}[\.]{1}[0-9]{4})|([\(]{1}[0-9]{3}[\)]{1}[0-9]{3}([\.]|[\-]){1}[0-9]{4}(([\ ]?(x|ext|extension)?)([\ ]?[0-9]{3,4}))?)|([1]{1}[\(]{1}[0-9]{3}[\)]{1}[0-9]{3}([\-]){1}[0-9]{4})|([\+]{1}[1]{1}[\ ]{1}[0-9]{3}[\.]{1}[0-9]{3}[\-]{1}[0-9]{4})|([\+]{1}[1]{1}[\ ]?[\(]{1}[0-9]{3}[\)]{1}[0-9]{3}[\-]{1}[0-9]{4}))$/) ? true : value.length === -1);
    const isValidEmail = (value) =>  !!([value] && value.length > 0 && value.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/));
    const isValidOption = (value) => !!([value] && value.length>1);

    const validateForm = () => {
     
        validateFormFields('firstname', firstname);
        validateFormFields('lastname', lastname);
        validateFormFields('email', email);
        validateFormFields('phone', phone);

        var validated = validfirstname && validphone && validlastname  && validemail;

        if(type==='Web Development - WordPress' || type==='Web Development - Other'){
            validateFormFields('industry', industry);
            validateFormFields('budget', budget);
            validateFormFields('websiteavailable', websiteavailable);
            validateFormFields('webname1', webname1);
            validateFormFields('logoavailable', logoavailable);
            validateFormFields('sellproducts', sellproducts);
            validateFormFields('blog', blog);
            validateFormFields('contentprovide', contentprovide);
            validateFormFields('imageprovide', imageprovide);
            validateFormFields('training', training);
            validateFormFields('updateweb', updateweb);
            return  validated && validindustry && validbudget && validwebsiteavailable && validwebname1 && validlogoavailable && validsellproducts && validblog && 
                     validcontentprovide && validimageprovide && validtraining && validupdateweb;
        }else if(type==="Mobile App Development"){
            validateFormFields('budget', budget);
            validateFormFields('logoavailable', logoavailable);
            validateFormFields('wireframes', wireframes);
            return  validated &&  validbudget && validlogoavailable && validwireframes;
        }else if(type==="Figma to React Component Conversion"){
            validateFormFields('screencount', screencount);
            validateFormFields('provideimages', provideimages);
            return validated &&  validscreencount && validprovideimages;
        }
    }

    const onSubmit = async () => {
        if (!validateForm()) return;
        console.log("Form values ", data);
        setLoading(true);
	  
        const {validfirstname, validlastname, validphone, validemail, validindustry, validbudget, validwebsiteavailable, validwebname1, validlogoavailable, validsellproducts, validblog,
            validcontentprovide, validimageprovide, validtraining, validupdateweb, validscreencount, validprovideimages, validwireframes, showModel, loading, ...otherDetails} = data;

	    await axios.post('https://services.incylabs.com/api/services/email/bulk', {
            "service" : "zoho",
            "account" : "fooder",
            "templateId" : "QUOTATION_REQUEST_TEMPLATE",
            "emailRequestList" : [{
                "key": "12314141241",
                "body": {
                    "from" : "Incy Labs <fooder@incylabs.com>",
                    "to": "incylabs@gmail.com",
                    "replyTo" : "No Reply",
                    "subject": "Quotation request",
                    "data": {...otherDetails}
                }
            }]
        }
        ).then(res => {
            if (res.status === 200){
                setLoading(false)
                setShowModel(true)
            } else {
                setLoading(false)
                console.log("Error sending email", res);
            }
        })
    }

    const handleModelAction = () => {
	  // eslint-disable-next-line no-restricted-globals
        location.reload(true)
        clearForm()
    }

    return (
        <section>
             {/* ref={top} */}
                <section className={'section-outer'} style={{borderBottom: 0}}>
                <Container>
                <Row>
                    <h2 style={{ margineTop: '25px'}}>Request a Quote</h2>
                </Row> 
                <Row>
                    <h6>* fields are required</h6>
                </Row>
                {loading ? <Spinner animation="border" /> : 
                (
                    <div>
                        <Form className={'quotation-form'}>
                            <h4 style={{textAlign: 'left', marginTop: '15px'}}>Type of Development</h4>
                            <Form.Row>
                                <Form.Group as={Col} lg={6} md={6} xs={12} sm={12} controlId="formGridName">
                                    <Form.Label>What type of Service do you require?</Form.Label><br/>
                                    <Form.Control as="select" value={type}
                                        onChange={(e) => onChange('type', e)}>
                                        {/* <option selected value="0">--Please choose an option --</option> */}
                                        <option defaultValue="Web Development - WordPress">Web Development - WordPress</option>
                                        <option value="Web Development - Other">Web Development - Other</option>
                                        <option value="Mobile App Development">Mobile App Development</option>
                                        <option value="Figma to React Component Conversion">Figma to React Component Conversion</option>
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                        <ContactInformation onChange={onChange} setPhoneNumber={setPhoneNumber} onSubmit={onSubmit} data={data}/>
                    
                        <Row style={{alignItems: 'left'}}>
                            <Col lg={12} md={12} sm={12} xs={12} style={{marginBottom: 30}}>
                                    {type && type==='Web Development - WordPress' ? <QuotationForm onChange={onChange} onSubmit={onSubmit} data={data}/> : (
                                            type==='Web Development - Other' ? <QuotationForm onChange={onChange} onSubmit={onSubmit} data={data}/> : (
                                                type==='Mobile App Development' ? <ApplicationInformation onChange={onChange} onSubmit={onSubmit} data={FormData}/> :
                                                <FigmaDesignInformation onChange={onChange} onSubmit={onSubmit} data={data}/>
                                            )
                                        )
                                    }
                            {/*<Image src={"images/logo-main.png"} lg={"auto"} md={"auto"} style={{width: '75%'}}/>*/}
                            </Col>
                        </Row>

                        <Form.Row>
                            <Form.Group as={Col} lg={6} md={6} xs={12} sm={12} controlId="formGridName">
                                <Form.Label>Do you have a timeline in mind for the development of this?</Form.Label>
                                <Form.Control
                                    type="text" placeholder="" onChange={(e) => onChange('timeline', e)}
                                    isValid={validtimeline} isInvalid={validtimeline !== undefined && !validtimeline} value={timeline}/>
                            </Form.Group>
                        </Form.Row>
                    

                        <Button style={{float: 'left',marginTop: '15px', width:'300px'}} block variant="primary" onClick={() => onSubmit()}>
                            Request Quote
                        </Button>
                    </div>
                )}
                </Container>
            </section>
            <Modal
                show={showModel}
                size="sm"
                centered
                className={'contact-model'}
                onHide={() => handleModelAction()}
            >
                <Modal.Body>
                <Row>
                    <Col lg={2} className={'model-content-icon'}>
                        <FaEnvelopeOpenText/>
                    </Col>
                    <Col lg={10}>
                        <h4 style={{paddingTop: 20}}>Hi, {firstname}!</h4>
                        <p>
                        We've received your message.. We will contact you soon!
                        </p>
                        <Button style={{float: 'right'}} onClick={() => handleModelAction()}>Okay</Button>
                    </Col>
                </Row>
                </Modal.Body>
            </Modal>
        </section>
	)
}

export default Quotation;