const products = [
    {
		src: 'images/pos-icon.png',
		headerImage: 'images/incypos-image.webp',
		heading: 'IncyPOS - Restaurant POS',
		description: "Incy's primary product is a complete, modern restaurant POS system that runs on Android devices. Our POS system is designed to make it easy for restaurant staff to take orders, process payments, and manage other tasks related to running a successful restaurant. With features like menu management, inventory tracking, and real-time sales reporting, our POS system has everything you need to keep your restaurant running smoothly and efficiently. Best of all, our POS system is available in the Google Playstore, making it easy for restaurant owners and staff to get started using it right away.",
		technologies: ['Android Client', 'ReactJs Back-office', 'NodeJs Backend', 'MYSQL DB', 'Stripe Payment Integration'],
		backEnd: [],
		link: 'https://www.incypos.com/'
    },
    {
		src: 'images/incytute-logo.png',
		headerImage: 'images/incytute-image.webp',
		heading: 'Incytute - School Management System',
		description: "Our school management application is a modern, web-based solution designed to streamline and simplify administrative tasks for educators and staff. With features like student registration, invoicing, and bank deposit management, our application makes it easy to manage all aspects of school operations. Additionally, we offer a range of other administrative services to help schools run smoothly and efficiently. Whether you're a school administrator, teacher, or staff member, our application has everything you need to streamline your work and focus on what matters most - educating students.",
		technologies: ['ReactJS, NodeJs', 'MongoDB'],
		backEnd: [],
		link: 'https://www.incytute.com/'
    },
    {
		src: 'images/musing-icon.png',
		headerImage: 'images/musing-image.webp',
		heading: 'Musing Live',
		description: "The platform connects people with common musical interests and enables them to collaborate on musical projects and arrange live music events. It is designed to be easy to use, with features that support spontaneous jams, meetups, and live gigs. Whether you are a solo artist looking for other musicians to play with, or a music enthusiast looking to attend live events, this platform is the perfect place to find and connect with others who share your passion for music. We are proud to have delivered a successful project for our client in Sweden, and to offer a platform that brings people together through the power of music.",
		technologies: ['React Native', 'Java Play', 'MongoDB', 'Wowza Streaming service'],
		backEnd: [],
		link: ''
    },
    {
		src: 'images/ugov-icon.png',
		headerImage: 'images/ugov-image.webp',
		heading: 'UGov - Incident Reporting App',
		description: "Our team developed a native Android app that allows citizens to easily report incidents in their communities. The app was designed for use across the country, and a government task force was established to periodically address the incidents reported through the app. In addition to the mobile app, we also developed a backoffice using ReactJS to manage the incidents reported by citizens. The backoffice allows government officials to track, prioritize, and respond to incidents in real-time. Our team was responsible for the design, development, and deployment of both the mobile app and the backoffice, and we are proud of the impact they have had in helping citizens stay safe and informed.",
		technologies: ['React Native', 'React based Back-office web app', 'Java Play service, MongoDB'],
		backEnd: [],
		link: ''
    },
	{
		src: 'images/fooder-icon.png',
		headerImage: 'images/fooder-image.webp',
		heading: 'Fooder - Food Ordering Platform',
		description: "Fooder is a food ordering and delivery platform designed by Incy. It is a comprehensive platform that allows users to order food from their favorite restaurants and have it delivered to their doorstep. In addition to the food ordering and delivery functionality, Fooder also integrates with Incypos, our POS client application. This allows restaurants to efficiently manage their orders and keep track of their sales through a single, unified platform. Our team at Incy was responsible for the design and development of Fooder, and we are proud to offer a solution that makes it easier for people to enjoy their favorite food from the comfort of their homes.",
		technologies: ['ReactJS client', 'Node.js', 'MYSQL DB'],
		backEnd: [],
		link: ''
	},
    {
		src: 'images/mashtapp-icon.png',
		headerImage: 'images/mashtapp-image.webp',
		heading: 'Mashtapp',
		description: "Our team was commissioned by a client to design an app for brewery lovers.The app is a guide to finding breweries that make traditional brews, and it is designed specifically for beer enthusiasts who are looking to discover new and interesting places to try craft beer. Whether you are a seasoned beer connoisseur or just starting to explore the world of craft beer, this app is a great resource for finding the best breweries in your area. We are proud to have delivered a successful project for our client and to offer an app that helps people discover and enjoy traditional brews from the best breweries around.",
		technologies: [''],
		backEnd: [],
		link: ''
    },
    {
		src: 'images/sck-icon.png',
		headerImage: 'images/sck-image.webp',
		heading: 'SCK Iconic Campus Web',
		description: " Our team worked closely with the client to understand their vision and needs, and used our expertise in WordPress to bring their website to life. The end result was a fully responsive and user-friendly website that effectively communicates the institute's brand and mission, and provides a valuable resource for students, faculty, and staff. This project showcases incy's ability to deliver high-quality web design and development services for education institutes.",
		technologies: ['Wordpress web application'],
		backEnd: [],
		link: 'https://www.sckiconiccampus.com/'
    },
    // {
	//   src: 'images/atoz-icon.png',
	//   headerImage: 'images/incypos-image.png',
	//   heading: 'A2Z',
	//   description: "A local listing service that allows service providers to run their service advertisements using graphic images.",
	//   technologies: ['React/Redux', 'Java Play service with MongoDB'],
	//   backEnd: []
    // },
    {
		src: 'images/wia_logo.webp',
		headerImage: 'images/wia-image.webp',
		heading: 'WIA Corporate Web',
		description: "Incy was responsible for the development of the corporate website for WIA Systems, a company that specializes in ERP customization and consultancy, as well as software development projects. Our team at incy worked closely with WIA Systems to create a professional website that accurately represents their brand and showcases their services. The resulting website is providing an excellent online presence for the company. ",
		technologies: ['Duda web application'],
		backEnd: [],
		link: 'https://www.wiasystems.com/'
    }
	// ,
    // {
	//   src: 'images/green-leaf-icon1.png',
	//   headerImage: 'images/incypos-image.png',
	//   heading: 'Green Leaf',
	//   description: "Offline first field service app for tea collectors.  ",
	//   technologies: ['Native Android', 'PHP/MySQL backend'],
	//   backEnd: []
    // }
]

export default products;