import React, { Component } from 'react';
import {Container} from "react-bootstrap";
import QuoteBanner from "../QuoteBanner";
import FooterContacts from "./FooterContacts";

class Footer extends Component{
    render() {
        return(
            <section className={'footer'}>
		    <Container style={{maxWidth: 1300, paddingTop: 60}}>
			  <QuoteBanner/>
			  <FooterContacts/>
		    </Container>
		</section>
	  )
    }
}
export default Footer;